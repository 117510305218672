<template>
	<section class="container">
		<div class="row mt-3">
			<template v-if="$can('Add', 'Company', 'Employees')">
				<div class="col-auto mt-1">
					<b-button @click="addInsurance"> Opret forsikringsaftale</b-button>
				</div>
			</template>
		</div>
		<b-form-input class="input col-12" v-model="globalfilter" placeholder="Søg" />
		<div class="row">
			<div class="col-12">
				<BaseTable :items="companyInsurances" :fields="fields" :isBusy="isBusy">
					<template v-slot:cell(name)="data">
						<router-link
							:to="{
								name: 'CompanyInsurance',
								params: { companyId: companyId, insuranceId: data.item.id },
							}"
						>
							{{ data.item.insurance.name }}
						</router-link>
					</template>
					<template v-slot:cell(groups)="data">
						<template v-for="groupId in data.item.groups">
							{{ getGroupName(groupId) }}
						</template>
					</template>
					<template v-slot:cell(supplier)="data">
						{{ getSupplierName(data.item.insurance.supplierId) }}
					</template>
				</BaseTable>
			</div>
		</div>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import dateMixin from '@/mixins/dateMixin'
export default {
	name: 'CompanyInsuranceList',
	mixins: [dateMixin],
	components: {
		BaseTable,
	},
	data() {
		return {
			globalfilter: '',
			calucationType: '%',
			fields: [
				{
					key: 'name',
					label: 'Aftalenavn',
					sortable: true,
				},
				{
					key: 'groups',
					label: i18n.t('employeeList.table.group'),
					sortable: true,
				},
				{
					key: 'supplier',
					label: 'Leverandør',
					sortable: true,
				},
			],
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		getSupplierName(supplierId) {
			const supplier = this.suppliers.find((g) => g.id === supplierId)
			return supplier?.name ?? ''
		},
		getGroupName(groupId) {
			const group = this.groups.find((g) => g.id === groupId)
			return group ? group.name : ''
		},
		async addInsurance() {
			const { value: insuranceName } = await this.$swal
				.fire({
					title: 'Indsæt navn for forsikringsaftale',
					input: 'text',
					inputValidator: (value) => {
						if (!value) {
							return 'En forsikringsaftale skal have et navn'
						}
					},
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ja opret!',
					showLoaderOnConfirm: true,
					preConfirm: async (insuranceName) => {
						try {
							var data = {
								insurance: { name: insuranceName },
							}
							var companyId = this.companyId
							var result = await this.$store.dispatch('companyVuex/addCompanyInsurance', { companyId, data })
							return result
						} catch (error) {
							console.log(error)
							this.$swal.showValidationMessage(`Request failed: ${error.message}`)
						}
					},
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.toast('Success', 'En forsikringsaftale er blevet oprettet', true)
						this.clicked = false
						//this.$router.go()
					}
				})
		},
	},
	computed: {
		companyInsurances() {
			return this.$store.getters['companyVuex/insurances']
		},
		companyId() {
			return this.$route.params.companyId
		},
		companyCvr() {
			return this.company.companyInfo.cvr
		},
		company() {
			var company = this.$store.getters['companyVuex/company']
			return company
		},
		isBusy() {
			return !this.$store.getters['companyVuex/isReady']
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
		groups() {
			return this.$store.getters['companyVuex/groups']
		},
	},
}
</script>

<style lang="scss"></style>
